import React, { useState } from "react";
import { submitRequest } from "../helpers/lambdaHelper";
import {
  Box,
  Button,
  Form,
  FormField,
  Heading,
  Text,
  TextArea,
  TextInput,
} from "grommet";

const AddOrEditLoggerAssociation = ({
  siteId,
  onCancel,
  onAdd,
  mode,
  dataToEdit,
}) => {
  const [isValid, set_isValid] = useState(false);
  const [state, set_state] = useState({
    loggerName: dataToEdit ? dataToEdit.logger_name : null,
    startdatetime: dataToEdit ? dataToEdit.start_date_time : null,
    enddatetime: dataToEdit ? dataToEdit.end_date_time : null,
    notes: dataToEdit ? dataToEdit.notes : "",
    warningText: null,
    isChanged: false,
  });

  return (
    <Box pad="medium" width={"large"} overflow="auto">
      {mode === "add" && <Heading size="small">Associate a new logger</Heading>}
      {mode === "edit" && (
        <Heading size="small">Edit logger association</Heading>
      )}

      <Form
        validate="change"
        onSubmit={() => {
          set_state({
            ...state,
            warningText: null,
          });
          if (mode === "add") {
            submitRequest("addInstallation", {
              siteid: siteId,
              loggername: state.loggerName,
              startdatetime: state.startdatetime,
              enddatetime: state.enddatetime === "" ? null : state.enddatetime,
              notes: state.notes,
            }).then(
              (result) => {
                if (result.status === "success") {
                  onAdd();
                }
                if (result.status === "dataWarning") {
                  set_state({
                    ...state,
                    warningText: result.message,
                  });
                }
              },
              (error) => {
                set_state({
                  ...state,
                  warningText: "There was an error processing this record.",
                });
              }
            );
          }
          if (mode === "edit") {
            submitRequest("editInstallation", {
              loggerinstallid: dataToEdit.installation_id,
              loggerid: dataToEdit.logger_id,
              siteid: siteId,
              startdatetime: state.startdatetime,
              enddatetime: state.enddatetime === "" ? null : state.enddatetime,
              notes: state.notes,
            }).then(
              (result) => {
                if (result.status === "success") {
                  onAdd();
                }
                if (result.status === "dataWarning") {
                  set_state({
                    ...state,
                    warningText: result.message,
                  });
                }
              },
              (error) => {
                set_state({
                  ...state,
                  warningText: "There was an error processing this record.",
                });
              }
            );
          }
        }}
        onValidate={(validationResults) => {
          set_isValid(validationResults.valid);
        }}
      >
        <Box>
          <FormField
            name="loggername"
            htmlFor="loggername-id"
            label="Logger Name:"
            required
          >
            <Box pad={"small"}>
              <TextInput
                id="loggername-id"
                name="loggername"
                value={state.loggerName}
                onChange={(event) => {
                  set_state({
                    ...state,
                    isChanged: true,
                    loggerName: event.target.value,
                  });
                }}
                required
                disabled={mode === "edit"}
              />
            </Box>
          </FormField>

          <FormField
            name="startdatetime"
            htmlFor="startdatetime-id"
            label="Start Date and Time (YYYY-MM-DD HH:MM:SS, time is optional):"
            required
          >
            <Box pad={"small"}>
              <TextInput
                id="startdatetime-id"
                name="startdatetime"
                value={state.startdatetime}
                onChange={(event) => {
                  set_state({
                    ...state,
                    isChanged: true,
                    startdatetime: event.target.value,
                  });
                }}
              />
            </Box>
          </FormField>
          <FormField
            name="enddatetime"
            htmlFor="enddatetime-id"
            label={
              <>
                End Date and Time (YYYY-MM-DD HH:MM:SS, <i>time is optional</i>
                ):
              </>
            }
          >
            <Box pad={"small"}>
              <TextInput
                id="enddatetime-id"
                name="enddatetime"
                value={state.enddatetime}
                onChange={(event) => {
                  set_state({
                    ...state,
                    isChanged: true,
                    enddatetime: event.target.value,
                  });
                }}
              />
            </Box>
          </FormField>
          <FormField name="notes" htmlFor="notes-id" label={"Notes:"}>
            <Box pad={"small"}>
              <TextArea
                id="notes-id"
                value={state.notes}
                onChange={(event) => {
                  set_state({
                    ...state,
                    isChanged: true,
                    notes: event.target.value,
                  });
                }}
              />
            </Box>
          </FormField>
        </Box>
        {state.warningText && (
          <Box pad={"small"}>
            <Text color={"status-warning"} size="xlarge">
              {state.warningText}
            </Text>
          </Box>
        )}
        <Box direction="row" gap="small">
          <Button
            primary
            label={mode === "add" ? "Add" : "Update"}
            type="submit"
            disabled={!isValid || !state.isChanged}
          />
          {mode === "edit" && (
            <Button
              primary
              label="Delete"
              onClick={() => {
                submitRequest("deleteInstallation", {
                  loggerinstallid: dataToEdit.installation_id,
                  loggerid: dataToEdit.logger_id,
                }).then(
                  (result) => {
                    if (result.status === "success") {
                      onAdd();
                    }
                  },
                  (error) => {
                    set_state({
                      ...state,
                      warningText: "There was an error deleting this record.",
                    });
                  }
                );
              }}
            />
          )}
          <Button label="Cancel" onClick={onCancel} />
        </Box>
      </Form>
    </Box>
  );
};

export default AddOrEditLoggerAssociation;
