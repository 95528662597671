const getApiVersion = () => {
  const hostName = document.location.hostname;
  let apiVersion = "prd"; // assume prop to start
  const subDomain = hostName.split(".")[0];
  if (["hestaging2", "deploy-stg", "stg"].indexOf(subDomain) > -1) {
    apiVersion = "stg";
  } else if (
    ["hedevelopment2", "feature", "bug", "dev"].indexOf(subDomain) > -1
  ) {
    apiVersion = "dev";
  } else if (hostName === "localhost") {
    apiVersion = "local";
  }

  // override the apiVersion if apiVersion is pass as a global variable in the url
  const urlSearch = document.location.search;
  if (urlSearch.length > 1 && urlSearch.indexOf("?") === 0) {
    const params = urlSearch.split("?")[1].split("&");
    params.forEach((param) => {
      const paramParts = param.split("=");
      if (
        paramParts.length > 1 &&
        paramParts[0] === "globals" &&
        paramParts[1].indexOf("apiVersion") === 0
      ) {
        apiVersion = paramParts[1].split(":")[1];
      }
    });
  }
  return apiVersion;
};

function getApiRoot() {
  const apiVersion = getApiVersion();

  const apiRoot =
    apiVersion === "local"
      ? "http://localhost:3999"
      : apiVersion === "dev"
      ? "https://api-dev.headwaterseconomics.org"
      : apiVersion === "stg"
      ? "https://api-stg.headwaterseconomics.org"
      : "https://api.headwaterseconomics.org";
  return apiRoot;
}

function getLambdaEndpoint() {
  return getApiRoot() + "/countingRec";
}

class LambdaError extends Error {
  constructor(message) {
    super(message); // (1)
    this.name = "LambdaError";
  }
}

function submitRequest(path, params) {
  let url = getLambdaEndpoint() + "/"+path;
  return new Promise(function (resolve, reject) {
    fetch(url, {
      method: "post",
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((data) => {
        resolve(data);
      })
      .catch(() => {
        const error = new LambdaError(
          "Error running lambda. Params: " + JSON.stringify(params)
        );
        reject(Error(error.name + ": " + error.message));
        throw error;
      });
  });
}


export { submitRequest };
