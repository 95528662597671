import React, { useState } from "react";
import { submitRequest } from "../helpers/lambdaHelper";
import {
  Box,
  Button,
  Form,
  FormField,
  Heading,
  Select,
  TextArea,
  TextInput,
} from "grommet";
import SiteLocationEditor from "./SiteLocationEditor";


const AddOrEditSite = ({ onCancel, onAdd, mode, dataToEdit }) => {
  const [isValid, set_isValid] = useState(false);

  const [projectId, set_projectId] = useState(
    mode === "edit" ? dataToEdit.project_id : null
  );
  const [siteName, set_siteName] = useState(
    mode === "edit" ? dataToEdit.site_name : null
  );
  const [trailName, set_trailName] = useState(
    mode === "edit" ? dataToEdit.trail_name : ""
  );
  const [trailNumber, set_trailNumber] = useState(
    mode === "edit" ? dataToEdit.trail_number : ""
  );
  const [trailSubsection, set_trailSubsection] = useState(
    mode === "edit" ? dataToEdit.trail_subsection : ""
  );
  const [latitude, set_latitude] = useState(
    mode === "edit" ? dataToEdit.latitude : null
  );
  const [longitude, set_longitude] = useState(
    mode === "edit" ? dataToEdit.longitude : null
  );
  const [notes, set_notes] = useState(mode === "edit" ? dataToEdit.notes : "");

  return (
    <Box pad="medium" width={"large"} overflow="auto">
      {mode === "add" && <Heading size="small">Add a new site</Heading>}
      {mode === "edit" && <Heading size="small">Edit site {dataToEdit.site_id}</Heading>}
      <Form
        validate="change"
        onSubmit={({ value }) => {
          if (mode === "add") {
            submitRequest("addSite", {
              projectid: projectId,
              sitename: siteName,
              trailname: trailName,
              trailnumber: trailNumber,
              trailsubsection: trailSubsection,
              latitude: latitude,
              longitude: longitude,
              notes: notes,
            }).then(
              (result) => {
                onAdd();
              },
              (error) => {
                onCancel();
              }
            );
          }
          if (mode === "edit") {
            submitRequest("editSite", {
              siteid: dataToEdit.site_id,
              projectid: projectId,
              sitename: siteName,
              trailname: trailName,
              trailnumber: trailNumber,
              trailsubsection: trailSubsection,
              latitude: latitude,
              longitude: longitude,
              notes: notes,
            }).then(
              (result) => {
                onAdd();
              },
              (error) => {
                onCancel();
              }
            );
          }
        }}
        onValidate={(validationResults) => {
          set_isValid(validationResults.valid);
        }}
      >
        <Box direction="row">
          <FormField
            name="projectid"
            htmlFor="projectid-id"
            label="Project:"
            required
          >
            <Box pad={"small"}>
              <Select
                id="projectid-id"
                name="projectid"
                options={["Helena", "Grants","Farmington"]}
                value={projectId}
                onChange={({ option }) => set_projectId(option)}
              />
            </Box>
          </FormField>

          <FormField
            name="sitename"
            htmlFor="sitename-id"
            label="Site name:"
            required
          >
            <Box pad={"small"}>
              <TextInput
                id="sitename-id"
                name="sitename"
                value={siteName}
                onChange={(event) => {
                  set_siteName(event.target.value);
                }}
              />
            </Box>
          </FormField>
        </Box>
        <Box direction="row">
          <FormField
            name="trailName"
            htmlFor="trailName-id"
            label="Trail name:"
          >
            <Box pad={"small"}>
              <TextInput
                id="trailName-id"
                value={trailName}
                onChange={(event) => set_trailName(event.target.value)}
              />
            </Box>
          </FormField>

          <FormField
            name="trailNumber"
            htmlFor="trailNumber-id"
            label="Trail number:"
          >
            <Box pad={"small"}>
              <TextInput
                id="trailNumber-id"
                value={trailNumber}
                onChange={(event) => set_trailNumber(event.target.value)}
              />
            </Box>
          </FormField>

          <FormField
            name="trailSubsection"
            htmlFor="trailSubsection-id"
            label="Trail subsection:"
          >
            <Box pad={"small"}>
              <TextInput
                id="trailSubsection-id"
                value={trailSubsection}
                onChange={(event) => set_trailSubsection(event.target.value)}
              />
            </Box>
          </FormField>
        </Box>

        <SiteLocationEditor latitude={latitude} longitude={longitude} set_latitude={set_latitude} set_longitude={set_longitude}/>
        
        <FormField name="notes" htmlFor="notes-id" label={"Notes:"}>
          <Box pad={"small"}>
            <TextArea
              id="notes-id"
              value={notes}
              onChange={(event) => set_notes(event.target.value)}
            />
          </Box>
        </FormField>

        <Box direction="row" gap="small">
          <Button primary label="Submit" type="submit" disabled={!isValid} />
          <Button label="Cancel" onClick={onCancel} />
        </Box>
      </Form>
    </Box>
  );
};

export default AddOrEditSite;
