import React, { useEffect, useState } from "react";
import { submitRequest } from "../helpers/lambdaHelper";
import { Box, Button, DataTable, Layer, Spinner, Text } from "grommet";
import {
  FormEdit as EditRowIcon,
  AddCircle as AddLoggerIcon,
} from "grommet-icons";
import AddOrEditSite from "./AddOrEditSite";
import AddOrEditLoggerAssociation from "./AddOrEditLoggerAssociation";
import SitesMap from "./SitesMap";
import styled from "styled-components";

const LoggerButton = styled(Button)`
  padding: 3px;
  border-radius: 5px;
  font-size: 10px;
  line-height: 12px;
`;

const Sites = () => {
  const [state, set_state] = useState({
    isSiteEditorVisible: false,
    siteEditorMode: null, // 'add' or 'edit'
    siteEditorData: null, // only applicable to edit mode
    sites: null,
    isSitesUpdated: false,
    isSitesMapVisible: false,
    isLoggerEditorVisible: false,
    loggerEditorMode: null, // 'add' or 'edit'
    loggerEditorData: null, // only applicable to edit mode
    initialized: false,
    currentSiteId: null,
  });

  useEffect(() => {
    if (!state.initialized) {
      submitRequest("listSites").then((result) => {
        set_state({
          ...state,
          sites: result,
        });
      });
    }
    set_state({
      ...state,
      initialized: true,
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (state.isSitesUpdated) {
      set_state({
        ...state,
        isSitesUpdated: false,
      });
      submitRequest("listSites").then((result) => {
        set_state({
          ...state,
          sites: result,
          isSitesUpdated: false,
        });
      });
    }
    // eslint-disable-next-line
  }, [state.isSitesUpdated]);

  const Loggers = ({ siteData }) => {
    return (
      <Box direction="row" wrap align="center">
        {siteData.logger_installs.map((install) => {
          const start_dt = new Date(install.start_date_time);
          const end_dt = install.end_date_time
            ? new Date(install.end_date_time)
            : null;
          const label = (
            <Box>
              <Box>{install.logger_name}</Box>
              <Box direction="row">
                <Box>{start_dt.toLocaleDateString()}</Box>
                <Box>{" - "}</Box>
                {end_dt ? (
                  <Box>{end_dt.toLocaleDateString()}</Box>
                ) : (
                  <Box>&#8734;</Box>
                )}
              </Box>
            </Box>
          );
          return (
            <Box pad="xsmall">
              <LoggerButton
                data={install}
                label={label}
                // label={install.logger_name+" ("+start_dt.toLocaleDateString()+"-Infinity)"}
                onClick={(test) => {
                  set_state({
                    ...state,
                    isLoggerEditorVisible: true,
                    loggerEditorMode: "edit",
                    loggerEditorData: install,
                    currentSiteId: siteData.site_id,
                  });
                }}
              />
            </Box>
          );
        })}
        {siteData.logger_installs.length === 0 && (
          <Text size="xsmall" color="red">
            None
          </Text>
        )}
        <Box pad="xsmall">
          <LoggerButton
            icon={<AddLoggerIcon size="small" />}
            label="Add"
            onClick={() => {
              set_state({
                ...state,
                isLoggerEditorVisible: true,
                loggerEditorMode: "add",
                currentSiteId: siteData.site_id,
              });
            }}
          />
        </Box>
      </Box>
    );
  };

  if (!state.sites)
    return (
      <Box
        align="center"
        justify="center"
        gap="small"
        direction="row"
        alignSelf="center"
        pad="large"
      >
        <Spinner />
      </Box>
    );
  return (
    <Box>
      <Box border>
        <DataTable
          pad={"small"}
          size="medium"
          columns={[
            {
              property: "site_id",
              header: "Site ID",
              primary: true,
              size: "25px",
            },
            { property: "project_id", header: "Project", size: "75px" },
            { property: "site_name", header: "Site Name", size: "150px" },
            {
              property: "loggers",
              render: (datum) => <Loggers siteData={datum} />,
              header: "Logger(s)",
              size: "220px",
            },
            { property: "trail_name", header: "Trail Name", size: "150px" },
            // { property: "trail_number", header: "Trail Number",size: "50px" },
            // { property: "trail_subsection", header: "Trail Subsection",size: "100px" },
            // { property: "notes", header: "Notes", size: "200px" },
            { property: "latitude", header: "Latitude", size: "65px" },
            { property: "longitude", header: "Longitude", size: "65px" },
            {
              property: "edit",
              size: "25px",
              render: (datum) => {
                // drop the project from the site name in the editor
                const d = {...datum, site_name: datum.site_name.slice(datum.site_name.indexOf(": ")+2)}
                return (
                  <Button
                    plain
                    icon={<EditRowIcon />}
                    onClick={() => {
                      set_state({
                        ...state,
                        isSiteEditorVisible: true,
                        siteEditorMode: "edit",
                        siteEditorData: d,
                      });
                    }}
                  />
                );
              },
            },
          ]}
          data={state.sites}
        />
      </Box>
      <Box direction="row" gap="medium" pad={"small"}>
        <Button
          label="Add new site"
          onClick={() => {
            set_state({
              ...state,
              isSiteEditorVisible: true,
              siteEditorMode: "add",
              siteEditorData: null,
            });
          }}
        />
        <Button
          label="View map of all sites"
          onClick={() => {
            set_state({
              ...state,
              isSitesMapVisible: true,
            });
          }}
        />
      </Box>
      {state.isSitesMapVisible && (
        <Layer
          full
          margin="medium"
          onEsc={() => {
            set_state({
              ...state,
              isSitesMapVisible: false,
            });
          }}
          onClickOutside={() => {
            set_state({
              ...state,
              isSitesMapVisible: false,
            });
          }}
        >
          <Box pad="small" fill>
            <SitesMap sites={state.sites} />
            <Box pad="small" direction="row">
              <Button
                primary
                label="Close"
                onClick={() => {
                  set_state({
                    ...state,
                    isSitesMapVisible: false,
                  });
                }}
              />
            </Box>
          </Box>
        </Layer>
      )}
      {state.isLoggerEditorVisible && (
        <Layer
          onEsc={() =>
            set_state({
              ...state,
              isLoggerEditorVisible: false,
              siteEditorMode: null,
              siteEditorData: null,
            })
          }
          onClickOutside={() =>
            set_state({
              ...state,
              isLoggerEditorVisible: false,
              siteEditorMode: null,
              siteEditorData: null,
            })
          }
        >
          <AddOrEditLoggerAssociation
            siteId={state.currentSiteId}
            onCancel={() =>
              set_state({
                ...state,
                isLoggerEditorVisible: false,
                // siteEditorMode: null,
                // siteEditorData: null,
              })
            }
            onAdd={() => {
              set_state({
                ...state,
                isLoggerEditorVisible: false,
                isSitesUpdated: true,
                // siteEditorMode: null,
                // siteEditorData: null,
              });
            }}
            mode={state.loggerEditorMode}
            dataToEdit={
              state.loggerEditorMode === "edit" ? state.loggerEditorData : null
            }
          />
        </Layer>
      )}
      {state.isSiteEditorVisible && (
        <Layer
          onEsc={() =>
            set_state({
              ...state,
              isSiteEditorVisible: false,
              siteEditorMode: null,
              siteEditorData: null,
            })
          }
          onClickOutside={() =>
            set_state({
              ...state,
              isSiteEditorVisible: false,
              siteEditorMode: null,
              siteEditorData: null,
            })
          }
        >
          <AddOrEditSite
            onCancel={() =>
              set_state({
                ...state,
                isSiteEditorVisible: false,
                siteEditorMode: null,
                siteEditorData: null,
              })
            }
            onAdd={() => {
              set_state({
                ...state,
                isSiteEditorVisible: false,
                siteEditorMode: null,
                siteEditorData: null,
                isSitesUpdated: true,
              });
            }}
            mode={state.siteEditorMode}
            dataToEdit={state.siteEditorData}
          />
        </Layer>
      )}
    </Box>
  );
};

export default Sites;
