import { css } from "styled-components";
export default {
  global: {
    colors: {
      brand: "#0D4D80",
    },
  },
  tab: {
    color: "dark-4",
    active: {
      background: "brand",
    },
    border: false,
    background: {
      color: "light-2",
    },
    pad: "small",
    margin: {
      horizontal:'small',
      vertical: '0px'
    },
  },
  formField: {
    border: {
      position: "none",
    },
  },
  dataTable: { 
    header: {
      font: {
        size: '0.8em',
        weight: 'bold'
      }
    },
    body:{
      extend: css`
        tr:nth-child(even) {
          background: #ddd;
        }
        tr:nth-child(odd) {
          background: #fff;
        }
        tbody>tr>td>div>span, tbody>tr>th>div>span {
          font-size: 0.8em !important;
        }
      `
    }
    
  }
};
