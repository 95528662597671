import React, { Children } from "react";
import Upload from "./components/Upload";
import Sites from "./components/Sites";
import ExploreData from "./components/ExploreData";
import { Grommet, Box, Heading, Tabs, Tab } from "grommet";
import theme from "./theme";



const StyledTabContent = ({ content }) => {
  return (
    <Box border pad="medium" elevation="small">
      {content}
    </Box>
  );
};

const App = () => {
  return (
    <Grommet theme={theme}>
      <Box pad="small" fill>
        <Heading>Headwaters Economics Trail Counter Data Portal</Heading>
      </Box>
      <Box pad="medium">
        <Box fill>
          <Tabs alignControls="start">
            <Tab title={"Upload Data"}>
              <StyledTabContent content={<Upload />} />
            </Tab>
            <Tab title={"Explore Data"}>
              <StyledTabContent content={<ExploreData />} />
            </Tab>
            <Tab title={"Sites"}>
              <StyledTabContent content={<Sites />} />
            </Tab>
            {/* <Tab title={"Projects (admin only)"}>
              <StyledTabContent content={'Comming Soon'} />
            </Tab>
            <Tab title={"Users (admin only)"}>
              <StyledTabContent content={'Comming Soon'} />
            </Tab> */}

          </Tabs>
        </Box>
      </Box>
    </Grommet>
  );
};

export default App;
