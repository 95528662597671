import HeMapboxComponent, {
  ControlContainer,
} from "@headwaters-economics/web-shared/components/Map";
import {
  Box,
  Button,
  CheckBox,
  FormField,
  Grid,
  Stack,
  Text,
  TextInput,
} from "grommet";
import { Marker } from "@urbica/react-map-gl";
import { Emergency as MapPin } from "grommet-icons";
import { useState } from "react";

const mapStyles = {
  outdoors: "mapbox://styles/mapbox/outdoors-v12",
  imagery: "mapbox://styles/mapbox/satellite-streets-v12",
};

const isLatitudeValid = (latitude) => {
  return latitude >= -90 && latitude <= 90;
};

const isLongitudeValid = (longitude) => {
  return longitude >= -180 && longitude <= 180;
};

const SiteLocationEditor = ({
  latitude,
  longitude,
  set_latitude,
  set_longitude,
}) => {
  const [selectedMapStyle, set_selectedMapStyle] = useState("outdoors");

  const [newLoc, set_newLoc] = useState(null);

  return (
    <Box pad="small">
      <Grid
        border
        areas={[
          ["latitude", "map"],
          ["longitude", "map"],
        ]}
        columns={["40%", "60%"]}
        height={"250px"}
      >
        <FormField
          name="latitude"
          htmlFor="latitude-id"
          label={<Box pad={{ vertical: "xsmall" }}>Latitude:</Box>}
          direction="row"
        >
          <Box pad={"xsmall"}>
            <TextInput
              id="latitude-id"
              value={latitude}
              onChange={(event) => set_latitude(event.target.value)}
            />
          </Box>
        </FormField>

        <FormField
          name="longitude"
          htmlFor="longitude-id"
          label={<Box pad={{ vertical: "xsmall" }}>Longitude:</Box>}
          direction="row"
        >
          <Box pad={"xsmall"}>
            <TextInput
              id="longitude-id"
              value={longitude}
              onChange={(event) => set_longitude(event.target.value)}
            />
          </Box>
        </FormField>

        <Box fill gridArea="map">
          <HeMapboxComponent
            mapStyle={mapStyles[selectedMapStyle]}
            onClick={(evt) => {
              set_newLoc({
                latitude: evt.lngLat.lat,
                longitude: evt.lngLat.lng,
                tooltipSide:
                  evt.target.transform.width / 2 > evt.point.x
                    ? "left"
                    : "right",
              });
            }}
          >
            <ControlContainer position="top-right">
              <Box gap='small'>
                
              <CheckBox
                checked={selectedMapStyle === "imagery"}
                label={"show imagery"}
                onChange={(event) => {
                  set_selectedMapStyle(
                    event.target.checked ? "imagery" : "outdoors"
                  );
                }}
              /><i>Click on map to update location.</i>
              </Box>
              
            </ControlContainer>
            {isLatitudeValid(latitude) && isLongitudeValid(longitude) && (
              <Marker longitude={longitude} latitude={latitude}>
                <MapPin color="red" size="medium" />
              </Marker>
            )}
            {newLoc && (
              <Marker longitude={newLoc.longitude} latitude={newLoc.latitude}>
                <Stack anchor={"top-" + newLoc.tooltipSide}>
                  <MapPin color="grey" size="medium" />
                  <Box
                    background={"light-1"}
                    border
                    margin={{
                      left: newLoc.tooltipSide === "left" ? "medium" : "none",
                      right: newLoc.tooltipSide === "right" ? "medium" : "none",
                    }}
                    pad="xsmall"
                    round="xsmall"
                    onClick={(e)=>e.stopPropagation()}
                  >
                    <Box>
                      <Text size="xsmall" color="blue">
                        Update location?
                      </Text>
                      <Box direction="row" gap="xsmall">
                        <Button
                          pad="large"
                          size="xsmall"
                          label="Yes"
                          onClick={() => {
                            set_latitude(Math.round(1000000*newLoc.latitude)/1000000);
                            set_longitude(Math.round(1000000*newLoc.longitude)/1000000);
                            set_newLoc(null);
                          }}
                        />
                        <Button
                          pad="xxsmall"
                          size="xsmall"
                          label="No"
                          onClick={() => {
                            set_newLoc(null);
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Stack>
              </Marker>
              
            )}
          </HeMapboxComponent>
        </Box>
      </Grid>
    </Box>
  );
};
export default SiteLocationEditor;
