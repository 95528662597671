import HeMapboxComponent, {
  ControlContainer,
} from "@headwaters-economics/web-shared/components/Map";
import { Box, CheckBox, FormField, Grid, Stack, Text, TextInput } from "grommet";
import { Marker } from "@urbica/react-map-gl";
import { RadialSelected as MapPin } from "grommet-icons";
import { useState } from "react";

const mapStyles = {
  outdoors: "mapbox://styles/mapbox/outdoors-v12",
  imagery: "mapbox://styles/mapbox/satellite-streets-v12",
};

const SitesMap = ({ sites }) => {
  const [selectedMapStyle, set_selectedMapStyle] = useState("outdoors");
  const [mapExtent, set_mapExtent] = useState({
    minLat: 35,
    minLon: -109,
    maxLat: 35.6,
    maxLon: -107,
  });

  return (
    <Box fill>
      <HeMapboxComponent
        mapStyle={mapStyles[selectedMapStyle]}
        extent={mapExtent}
      >
        <ControlContainer position="top-right">
          <CheckBox
            checked={selectedMapStyle === "imagery"}
            label={"show imagery"}
            onChange={(event) => {
              set_selectedMapStyle(
                event.target.checked ? "imagery" : "outdoors"
              );
            }}
          />
        </ControlContainer>
        {sites.map((site) => {
          return (
            <Marker longitude={site.longitude} latitude={site.latitude}>
              <Stack anchor="top-left">
                <MapPin color="blue" size="medium" />
                <Box  background={'light-1'} border width='xsmall' margin={{left:'medium'}} pad='xsmall' round='xsmall' >
                  <Text size="xsmall" color='blue' >{site.site_name}</Text>
                </Box>
              </Stack>
            </Marker>
          );
        })}
      </HeMapboxComponent>
    </Box>
  );
};
export default SitesMap;
