import React, { useState } from "react";
import { submitRequest } from "../helpers/lambdaHelper";
import {
  Box,
  FileInput,
  TextInput,
  Form,
  FormField,
  Button,
  Spinner,
  Layer,
  Text,
  Tip,
  TextArea,
} from "grommet";
import { CircleInformation } from "grommet-icons";

const Messages = ({ messages, level }) => {
  if (!level) {
    level = 0;
  }
  return messages.map((msg) => {
    if (typeof msg === "object") {
      return (
        <ul>
          <Messages messages={msg} level={level + 1} />
        </ul>
      );
    } else {
      if (level === 0) {
        return <Text>{msg}</Text>;
      }
      return <li>{msg}</li>;
    }
  });
};

const Upload = () => {
  const [userName, set_userName] = useState("");
  const [fileToUpload, set_fileToUpload] = useState(null);
  const [notes, set_notes] = useState("");
  const [isUploading, set_isUploading] = useState(false);
  const [messages, set_messages] = useState(null);

  return (
    <Box >
      {!messages && (
        <Box width="large" gap="small" >
          <Form
            onSubmit={({ value }) => {
              const fileReader = new FileReader();
              fileReader.onload = () => {
                set_isUploading(true);
                submitRequest('uploadFile',{
                  uploadedby: userName,
                  filename: fileToUpload.name,
                  filecontents: fileReader.result,
                  notes: notes,
                }).then(
                  (result) => {
                    set_isUploading(false);
                    set_messages(result.summary);
                    set_fileToUpload(null);
                  },
                  (error) => {
                    set_isUploading(false);
                    set_messages([
                      "Sorry, there was an error uploading " +
                        fileToUpload.name +
                        "!",
                    ]);
                    set_fileToUpload(null);
                  }
                );
              };
              fileReader.readAsText(fileToUpload);
            }}
          >
            <FormField
              name="name"
              htmlFor="name-text-input-id"
              label="Your name:"
            >
              <Box pad={"small"}>
                <TextInput
                  id="name-text-input-id"
                  placeholder="First and last name"
                  value={userName}
                  onChange={(event) => set_userName(event.target.value)}
                />
              </Box>
            </FormField>
            <FormField
              name="files"
              htmlFor="files-input-id"
              label="Files to upload:"
            >
              <Box pad={"small"}>
                <FileInput
                  id="files-input-id"
                  onChange={(event) => {
                    const files = event.target.files;
                    if (files && files.length > 0) {
                      set_fileToUpload(files[0]);
                    } else {
                      set_fileToUpload(null);
                    }
                    set_messages(null);
                  }}
                />
              </Box>
            </FormField>
            <FormField
              name="notes"
              htmlFor="notes-id"
              label={
                <Box direction="row">
                  Notes:
                  <Tip
                    content={"Notes can include any important details about your trip into the field to collect data. "}
                    dropProps={{background:'light-1'}}
                  >
                    <Button plain icon={<CircleInformation size='medium'/>} />
                  </Tip>
                </Box>
              }
            >
              <Box pad={"small"}>
                <TextArea
                  id="notes-id"
                  value={notes}
                  onChange={(event) => set_notes(event.target.value)}
                />
              </Box>
            </FormField>

            <Box direction="row" gap="medium">
              <Button
                type="submit"
                primary
                label="Submit"
                disabled={!fileToUpload || userName === ""}
              />
            </Box>
          </Form>
          {isUploading && (
            <Layer>
              <Box
                align="center"
                justify="center"
                gap="small"
                direction="row"
                alignSelf="center"
                pad="large"
              >
                <Spinner />
                <Text>Loading...</Text>
              </Box>
            </Layer>
          )}
        </Box>
      )}
      {messages && (
        <Box>
          <Messages messages={messages} />
          <Box direction="row" gap="medium">
            <Button
              primary
              label="Upload another file"
              onClick={() => {
                set_messages(null);
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Upload;
