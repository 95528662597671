import { Box, Grid, Select, Spinner, Text } from "grommet";
import { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceArea,
  Brush,
} from "recharts";
import { submitRequest } from "../helpers/lambdaHelper";

const colorPalette = ["#B1302D", "#DCB000", "#247BA0", "#00846E", "#50514F"];

const CustomTooltip = ({ active, payload, label }) => {
  
  if (active && payload && payload.length) {
    const dt = new Date(payload[0].payload.day);
    return (
      <Box border elevation="small" background={"light-1"}>
        <Box pad="small" background={"dark-3"}>
          <Text weight={"bold"}>{dt.toUTCString().substring(0,16)}</Text>
        </Box>
        <Grid pad="small" columns={["auto", "auto"]}>
          {payload.map((data) => {
            return (
              <>
                <Box>
                  <Text color={data.color}>{data.name}:</Text>
                </Box>
                <Box>{data.value}</Box>
              </>
            );
          })}
        </Grid>
      </Box>
    );
  }

  return null;
};

const ExploreData = () => {
  const [state, set_state] = useState({
    sites: null,
    counts: null,
    selectedSite: null,
    initialized: false,
    WeekendReferenceAreas: [],
  });

  useEffect(() => {
    if (!state.initialized) {
      submitRequest("listSites").then((result) => {
        set_state({ ...state, sites: result });
      });
    }
    set_state({ ...state, initialized: true });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (state.selectedSite !== null) {
      submitRequest("getCounts", { siteid: state.selectedSite }).then(
        (result) => {
          // get the weekend reference areas
          let allRefAreas = [];
          let saturday = null;
          let sunday = null;
          result.data.forEach((item) => {
            const dt = new Date(item.day);
            const dayOfWeek = dt.getUTCDay();
            if (dayOfWeek === 6) {
              sunday = item.day;
            }
            if (dayOfWeek === 0) {
              saturday = item.day;
            }
            if (saturday && sunday) {
              allRefAreas.push(
                <ReferenceArea
                  x1={saturday}
                  x2={sunday}
                  y1={0}
                  y2={1000000}
                  stroke="transparent"
                  ifOverflow="hidden"
                />
              );
            }
            if (dayOfWeek === 1) {
              saturday = null;
              sunday = null;
            }
          });

          set_state({
            ...state,
            counts: result,
            WeekendReferenceAreas: allRefAreas,
          });
        }
      );
    }
    // eslint-disable-next-line
  }, [state.selectedSite]);

  return (
    <Box gap="medium">
      {!state.sites && (
        <Box
          align="center"
          justify="center"
          gap="small"
          direction="row"
          alignSelf="center"
          pad="large"
        >
          <Spinner />
        </Box>
      )}
      {state.sites && (
        <Box direction="row">
          <Box pad="xsmall">Select a site:</Box>
          <Box width={"medium"}>
            <Select
              id="projectid-id"
              name="projectid"
              options={state.sites.concat([
                {
                  site_id: 0,
                  site_name:
                    "** Unknown site - data not yet associated with a site.",
                },
              ])}
              labelKey={"site_name"}
              valueKey={"site_id"}
              value={state.selectedSite}
              onChange={({ option }) => {
                set_state({
                  ...state,
                  selectedSite: option.site_id,
                  counts: null,
                });
              }}
            />
          </Box>
        </Box>
      )}
      {state.selectedSite !== null && !state.counts && (
        <Box
          align="center"
          justify="center"
          gap="small"
          direction="row"
          alignSelf="center"
          pad="large"
        >
          <Spinner />
        </Box>
      )}
      {state.selectedSite === 0 && state.counts && state.counts.data.length === 0 && state.sites.length === 1 && (
        <>
          No data has been uploaded yet.  
        </>
      )}
      {state.selectedSite === 0 && state.counts && state.counts.data.length === 0 && state.sites.length > 1 && (
        <>
          All data has already been associated with a site. 
        </>
      )}
      {state.selectedSite !== 0 && state.counts && state.counts.data.length === 0 && (
        <>
          No loggers have been associated with this site yet. Use the Sites tab
          to associate loggers.
        </>
      )}
      {state.counts && state.counts.data.length > 0 && (
        <Box height="medium">
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={state.counts.data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <XAxis
                dataKey="day"
                tickFormatter={(dtStr) => {
                  
                  const dt = new Date(dtStr);
                  // console.debug(dtStr,dt.toLocaleDateString("en-US", { timeZone: 'UTC' }))
                  return dt.toLocaleDateString("en-US", { timeZone: 'UTC' });
                }}
                // tick={false}
              />
              <YAxis />
              <Tooltip content={<CustomTooltip />} />
              <Legend />
              {state.WeekendReferenceAreas.map((refArea) => refArea)}
              {state.counts.loggers.map((logger, i) => {
                return (
                  <Line
                    type="monotone"
                    name={logger}
                    dataKey={"total_" + logger}
                    stroke={colorPalette[i % colorPalette.length]}
                    activeDot={{ r: 5 }}
                    dot={false}
                  />
                );
              })}
              <Brush
                dataKey={"day"}
                tickFormatter={(dtStr) => {
                  const dt = new Date(dtStr);
                  return dt.toLocaleDateString("en-US", { timeZone: 'UTC' });
                }}
              />
            </LineChart>
          </ResponsiveContainer>
        </Box>
      )}
    </Box>
  );
};

export default ExploreData;
